* {
  margin: 0;
  padding: 0;
}

$primary: #212121;
$grey: #999999;
$white: whitesmoke;

body {
  background-color: $white;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  flex: 1;
}

.column-2 {
  flex: 2;
}

.heading {
  font-size: 72px;
  text-align: start;
  font-weight: 500;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  @extend .center;
  padding: 0 13%;
  background-color: $white;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 32px;

    .app-text-name-container {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      padding: 0 32px;
    }

    .app-text-details-container {
      overflow-x: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25vh;
      flex-direction: column;
    }

    .app-mobile-container {
      min-height: 100vh;
    }
  }

  .heading-text {
    color: $grey;
    text-align: start;
    margin-top: 8px;
    line-height: 26px;
    width: 70%;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 32px;
    }
  }
}

.quote-section {
  height: 50vh;
  display: flex;
  padding: 0 13%;
  background-color: $primary;
  margin-top: 5%;
  @extend .center;

  .quote-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .quote {
      font-size: 32px;
      color: $white;
      margin-bottom: 12px;
      font-weight: 500;
    }

    .quote-author {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .project-logo {
    height: 70px;
    margin: 24px;
  }

  .logo-aiesec {
    height: 60px;
    width: 60px;
  }

  .logo-climber {
    height: 70px;
    margin-left: 10px;
  }
}

.work-experience {
  height: 100vh;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.instructions-container{
  @extend .center;
  height: 10vh;
  background-color: $primary;
  padding: 40px;
  .instructions-text{
    color: #c1c1c1;
    line-height: 26px;
  }

  @media (max-width: 768px) {
    padding: 10vh 40px;
  }
}