$primary: #212121;
$grey: #999999;
$white: whitesmoke;

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-container {
  height: 35vh;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  margin-bottom: 16px;
  background-color: $white;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05), -5px -5px 10px white;
  position: relative;
  z-index: 0;

  a {
    color: $primary !important;
    text-decoration: none !important;
  }

  .product-card-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    transition: 0.05s ease-in-out;

    h3 {
      margin: 16px 0;
      color: #242424;
    }

    .store-link-container {
      @extend .center;
      height: 40px;
      width: 40px;
      margin: 4px 8px;
      border-radius: 100%;
      transition: 0.2s ease-in-out;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05), -5px -5px 10px white;

      &:hover {
        box-shadow: none;
      }

      &:active {
        box-shadow: -5px -5px 10px white inset,
          5px 5px 10px rgba(0, 0, 0, 0.05) inset;
      }
    }
  }

  .badge-container {
    position: absolute;
    right: 0;
  }
}
