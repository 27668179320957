$primary: #212121;
$grey: #999999;
$white: whitesmoke;
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-exterior-container {
  align-self: center;
  height: 75vh;
  width: 37vh;
  border-radius: 50px;
  background-color: black;
  padding: 12px;
  box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.3),
    10px 10px 20px rgba(255, 255, 255, 0.4) inset;
  transition: 0.3s ease-in-out;

  &:hover {
    box-shadow: 60px 60px 60px rgba(0, 0, 0, 0.3), 0px 0px 10px whitesmoke inset;
    transform: scale(1.01);
  }
  .scrollable-content::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    box-shadow: none;
    &:hover {
      transform: scale(1);
      box-shadow: none;
    }
  }

  .tab-interior-container {
    height: 100%;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: whitesmoke;

    .scrollable-content {
      overflow-y: scroll;
      overflow-x: hidden;
      scroll-behavior: smooth;

      .profile-picture {
        margin-top: 24px;
        height: 100px;
        width: 100px;
        border-radius: 100px;
      }

      .heading-container {
        margin-top: 40px;
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-start;
        padding: 0 36px;
      }
    }
    .navbar {
      max-height: 7vh;
      position: relative;
      max-width: 100%;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      display: flex;
      justify-content: space-evenly;
      padding: 16px;
    }

    .notch-container {
      width: 100%;
      padding-top: 16px;
      padding-left: 24px;
      z-index: 2;
      position: absolute;
      padding-bottom: 32px;
      .notch {
        position: absolute;
        height: 10px;
        width: 20px;
        border-radius: 10px;
        background-color: black;
      }
    }
  }
}

.mobile-row-container {
  display: flex;
  min-height: 50px;
  align-items: flex-start;
  margin: 24px 0;
  padding: 0 36px;

  .column {
    width: 45%;
    display: flex;
    .column-heading {
      font-weight: 500;
    }

    .column-value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: rgba(0, 0, 0, 0.3);
    }
  }
}

.mobile-button-container {
  padding: 1.3vh;
  border-radius: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  @extend .center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.mobile-button-selected {
  background-color: #000;

  &:hover {
    background-color: #000;
  }
}

.mobile-work-container {
  h4 {
    font-size: 14px;
    color: $grey;
  }

  .commutatus-work {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;

    .project-card-container {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 24px;

      .logo-blok {
        height: 7vh;
      }

      .logo-common {
        height: 8vh;
      }

      .logo-common-md {
        height: 9vh;
      }

      .logo-common-lg {
        height: 9vh;
        width: 9vh;
      }
    }
  }
}

.mobile-home-container {
  .navigation-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px;

    .navigation-card {
      @extend .center;
      flex-direction: column;
      padding: 8px;
      flex-grow: 1;
      flex-shrink: 1;
      margin: 8px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05), -5px -5px 10px white;
      height: 20vh;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      p {
        margin-top: 8px;
        font-size: 12px;
        color: #949494;
      }

      &:hover {
        box-shadow: none;
      }

      &:active {
        box-shadow: -5px -5px 10px white inset,
          5px 5px 10px rgba(0, 0, 0, 0.05) inset;
      }
    }
  }
}

.mobile-contact-container {
  padding: 32px;
  .contact-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    .contact-heading-container {
      display: flex;
      justify-content: flex-start;
      width: 50%;
    }

    .contact-value-container {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      word-break: break-all;
      text-align: left;
      color: $grey;

      a {
        color: $grey !important;
      }
    }
  }
}
